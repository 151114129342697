import { axiosJwt } from '@/shared/auth/axiosJwt';

const api = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_NAN_PORT}${process.env.VUE_APP_API_PATH}/nan`;


export default {
    obterParceiroPjPorCnpjSSE(cnpj) {
        return axiosJwt.get(`${api}/parceiropj?cnpj=${cnpj}`);
    },

    atualizarParceiroPj(pj) {
        return axiosJwt.post(`${api}/parceiropj/atualizar`, pj);
    },
};
